<template>
  <div class="mb-4">
    <hr>
    <div class="item-top-area d-flex align-items-center">
      <div class="item-right mr-auto">
        <div class="switch-toggle">
          <span>🎈 Animation</span>
        </div>
      </div>
      <div class="item-right ml-auto">
        <div class="switch-toggle">
          <label class="switch" for="show_title">
            <input @click="toggleAnimation()" :checked="animationObj.animation_enabled" :value="animationObj.animation_enabled"
                   type="checkbox"
                   id="show_title"/>
            <div class="slider round"></div>
          </label>
        </div>
      </div>
    </div>
    <div v-if="animationObj.animation_enabled" class="mt-3 input_icon_text d-flex align-items-center">
      <div class="style_body d-flex align-items-center">
        <div :class="{'active_style':item.selected}" v-tooltip="item.name" class="ml-2 d-flex align-items-center"  v-for="(item,index) in animationList" @click="selectAnimation(index)">
          <img class="img-fluid ml-1" width="20px" :src="item.image"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {animationList} from '@/common/attributes'
export default ({
  data () {
    return {
      animationObj: {
        animation_enabled: this.animation_enabled,
        animation: this.animation
      },
      animationList: animationList
    }
  },
  created () {
    this.animationObj.animation_enabled = this.animation_enabled
    this.animationList.filter((item) => item.selected = item.key === this.animation)
  },
  props: [
    'animation_enabled',
    'animation'
  ],
  methods: {
    toggleAnimation () {
      this.animationObj.animation_enabled = !this.animationObj.animation_enabled
      this.$emit('animationObj', this.animationObj)
    },
    selectAnimation (index) {
      this.animationList.filter((item, i) => item.selected = i === index)
      this.animationObj.animation = this.animationList[index].key
      this.$emit('animationObj', this.animationObj)
    }
  },
  watch: {
  }

})
</script>

<style scoped lang="less">
.style_body {
  div {
    background: #eff4ff;
    border: 1px solid #eff4ff;
    padding: 4px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    box-shadow: 0 3px 6px #00000029;
    &:hover {
      border: 1px solid #3988fe;
    }
  }

  .active_style {
    border: 1px solid #3988fe !important;
  }
}
</style>
